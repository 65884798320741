import { createChatBotMessage } from 'react-chatbot-kit';
import { BotWidget } from './widget.js';
import {
  BotAvatar,
  BotMessageBox,
  UserMessageBox,
  ButtonOptions,
} from './components';

const botName = 'Highpoint assist';

const APbotConfig = {
  botName: botName,
  initialMessages: [
    createChatBotMessage(`Hi! I'm ${botName}`, {
      widget: 'buttonOption',
    }),
  ],

  widgets: [
    {
      widgetName: 'HPlogo',
      widgetFunc: (props) => <BotWidget {...props} />,
    },
    {
      widgetName: 'buttonOption',
      widgetFunc: (props) => <ButtonOptions {...props} />,
    },
  ],

  customComponents: {
    // Replaces the default header
    // header: () => (
    //   <div style={{ padding: '5px', borderRadius: '3px' }}>{botName}</div>
    // ),
    // Replaces the default bot avatar
    botAvatar: (props) => <BotAvatar {...props} />,
    // Replaces the default bot chat message container
    // botChatMessage: (props) => <BotMessageBox {...props} />,
    // // Replaces the default user icon
    // userAvatar: (props) => <></>,
    // // Replaces the default user chat message
    // userChatMessage: (props) => <UserMessageBox {...props} />,
  },
};

export { APbotConfig };
