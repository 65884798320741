import React from 'react';
// import icon from './favicon.ico';

function BotAvatar() {
  return (
    <div className="bot-avatar">
      <img src="./favicon.ico" alt="logo" />
    </div>
  );
}

export { BotAvatar };
