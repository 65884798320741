import React, { useState, useEffect } from 'react';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import { APbotConfig } from './config'; // Assuming you have config.js with bot configurations
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import './ChatBot.scss';


function HpChatBot() {
  
  const [botEnable, setBotEnable] = useState(false);
  const [botConfig, setBotConfig] = useState(APbotConfig);
  const [visible, setVisible] = useState(false);

  const botConfigMap = new Map([
    ['Accounts Payable', APbotConfig],
    // ['Purchase Orders', PObotConfig],
  ]);
  useEffect(() => {
    setVisible(true);

    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const content = <div>Hi! How can I assist you?</div>;

  return (
    <Chatbot
            config={botConfig}
            messageParser={MessageParser}
            actionProvider={(props) => (
              <ActionProvider {...props} />
            )}
          />
    // <div className="hp-bot-container">
    //   <input type="checkbox" className="bot-display-action-control" id="hpcb" />
    //   {/* <Popover
    //     content={content}
    //     visible={visible}
    //     onClick={() => setVisible(!visible)}
    //   > */}
    //   <div className="chat-bot-start">
    //     <span className="icon-reddit bot-icon"></span>
    //     <div className="chat-container">
    //       <div className="hp-bot-header">
    //         <div className="header-bot-icon-container">
    //         </div>
    //         <div className="bot-name-container">
    //           <span className="bot-name">High Point Support</span>
    //           <div className="bot-status">
    //             <div className="bot-status-circle"></div>
    //             <span className="bot-status-text">Online</span>
    //           </div>
    //         </div>{' '}
    //       </div>
    //       <Chatbot
    //         config={botConfig}
    //         messageParser={MessageParser}
    //         actionProvider={(props) => (
    //           <ActionProvider {...props} />
    //         )}
    //       />
    //     </div>
    //   </div>
    //   {/* </Popover> */}
    // </div>
  );
}

export default HpChatBot;
