import React from 'react';

const ButtonOptions = (props) => {
  const options = [
    { text: 'Accounts payable', id: 1 },
    { text: 'Invoices', id: 2 },
    { text: 'Email', id: 3 },
    { text: 'Approvals', id: 4 },
    { text: 'Help', id: 5 },
    { text: 'Other', id: 6 },
  ];

  const buttonHandler = (txt) => {
    props.actionProvider.handleButtonClick(txt);
  };

  const buttonsMarkup = options.map((option) => (
    <button
      key={option.id}
      onClick={() => buttonHandler(option.text)}
      className="option-button"
    >
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export { ButtonOptions };
