import React, { useEffect, useRef } from 'react';
import io from 'socket.io-client';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const socketRef = useRef(null);

  useEffect(() => {
    // socketRef.current = io('http://dtrain.highpointsuite.com/chatbot/');
    // socketRef.current = io('http://127.0.0.1:5000/');
    socketRef.current = io('http://dtrain.highpointsuite.com/');
    // socketRef.current = io("http://dtrain.highpointsuite.com/socket.io", {transports: ["websocket", "polling"]});
    // socketRef.current = io("ws://dtrain.highpointsuite.com/", {path: "/socket.io",transports: ["websocket","polling"],reconnection: true,reconnectionAttempts: 5,reconnectionDelay: 1000,});
    // socketRef.current = io.connect('http://dtrain.highpointsuite.com/socket.io', { transports: ['polling'] });
    // socketRef.current = io("ws://dtrain.highpointsuite.com/socket.io", {transports: ["websocket"]});
    console.log(socketRef.current);
    const socket = socketRef.current;

    socket.on('my_response', (message) => {
      console.log(message);
      const botMessage = createChatBotMessage(message.message);
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    });

    socket.on("connect_error", (err) => {// the reason of the error, for example "xhr poll error"
      console.log(err.message);
      // some additional description, for example the status code of the initial HTTP response
      console.log(err.description);
      // some additional context, for example the XMLHttpRequest object
      console.log(err.context);
    });

      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }, [createChatBotMessage, setState]);

    const handleHello = (message) => {
      const socket = socketRef.current;

      //socket.emit('handle_event', message);
      //debugger;
      // socket.emit('handle_event', {
      //   event: 'message',
      //   message: message,
      // });
      // console.log(message);
      socket.emit('message', {message: message,});
      console.log(message);

      // const botMessage = createChatBotMessage('Hello. Nice to meet you.');
      // setState((prev) => ({
      //   ...prev,
      //   messages: [...prev.messages, botMessage],
      // }));
    };
    const handleButtonClick = (data) => {
      const socket = socketRef.current;
      if (socket) {
        const userMessage = createChatBotMessage(data, {
          sender: 'user',
          type: 'user',
        });

        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, userMessage],
        }));

        // Emit the message via socket
        //socket.emit('handle_event', data);
        socket.emit('handle_event', {
          event: 'message',
          message: data,
        });

      } else {
        console.error('Socket is not initialized.');
      }
    };

    return (
      <div>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            actions: {
              handleButtonClick,
              handleHello,
            },
          });
        })}
      </div>
    );
  };

  export default ActionProvider;
