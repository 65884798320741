import React, { useEffect, useState } from 'react';

const BotWidget = () => {
  const [imageUrl, setImageUrl] = useState('');

  //   useEffect(() => {
  //     fetch(
  //       'https://www.google.com/imgres?q=avatar%20images&imgurl=https%3A%2F%2Fsm.ign.com%2Fign_nordic%2Fcover%2Fa%2Favatar-gen%2Favatar-generations_prsz.jpg&imgrefurl=https%3A%2F%2Fnordic.ign.com%2Favatar-generations&docid=wjdb3XJbc0CZ8M&tbnid=iK0aSJqa8CD5XM&vet=12ahUKEwiLweWfnLyGAxUK4TgGHTJyAJ4QM3oECGsQAA..i&w=1024&h=1024&hcb=2&ved=2ahUKEwiLweWfnLyGAxUK4TgGHTJyAJ4QM3oECGsQAA'
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setImageUrl(data);
  //       });
  //   }, []);

  return (
    <div
    // style={{
    //   display: 'flex',
    //   justifyContent: 'center',
    // }}
    >
      <img
        style={{ height: '40px', width: 'auto' }}
        // Height should be a multiple of 8, or at the very least 4
        alt="Client Logo"
      />
    </div>
  );
};

export { BotWidget };
